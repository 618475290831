var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LANGUAGE_CODE, getAccessToken, getItem, storeItem } from '../../../utils/LocalStorageUtil';
import Logo from '../../../assets/images/obranding_header_logo_2.png';
import CloseIcon from '../../../assets/images/new_close.png';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { checkRoleUser, filterTabsByPermission } from "../../../utils/CommonUtil";
import { ROLE_TYPE, SUB_URL } from "../../../config";
import Loading from "../../../components/common/loadmore-btn/Loading";
import NetworkConnector from '../../../services/NetworkConnector';
import { DEFAULT_ROLE } from '../../../config/index';
import { MyContext } from '../Header';
import { useTranslation } from 'react-i18next';
import { userStorage } from 'services';
var MyUserProfile = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myProfile/MyUserProfile'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyVouchers = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myVouchers/MyVouchers'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyMemberships = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myMemberships/MyMemberships'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyFans = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myFans/MyFans'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyPurchasedItems = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myPurchasedItems'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyFreeItems = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myFreeItems'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyFavoriteBrands = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myFavoriteBrands/MyFavoriteBrands'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
// const AffiliateRegister = Loadable(
//     {
//         loader: () => import('../../../pages/brand-admin/affiliateRegister/AffiliateRegister'),
//         loading: () => <Loading/>,
//         render(loaded, props: any) {
//             const Alerts = loaded.default;
//             return <Alerts {...props} />
//         }
//     }
// );
// const RegisterForm = Loadable(
//     {
//         loader: () => import('../../../pages/brand-admin/affiliateRegister/RegisterForm'),
//         loading: () => <Loading/>,
//         render(loaded, props: any) {
//             const Alerts = loaded.default;
//             return <Alerts {...props} />
//         }
//     }
// );
var MyAdmnin = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/MyAdmnin'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
// const MyManageLink = Loadable(
//     {
//         loader: () => import('../../../pages/brand-admin/myLink/manageLink/ManageLink'),
//         loading: () => <Loading/>,
//         render(loaded, props: any) {
//             const Alerts = loaded.default;
//             return <Alerts {...props} />
//         }
//     }
// );
// const MyCreateLink = Loadable(
//     {
//         loader: () => import('../../../pages/brand-admin/myLink/createLink'),
//         loading: () => <Loading/>,
//         render(loaded, props: any) {
//             const Alerts = loaded.default;
//             return <Alerts {...props} />
//         }
//     }
// );
var MyLink = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myLink'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var CampaignStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/campaignStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
// const AffiliateStaff = Loadable(
//     {
//         loader: () => import('../../../pages/brand-admin/myAdmin/components/affiliateStaff'),
//         loading: () => <Loading/>,
//         render(loaded, props: any) {
//             const Alerts = loaded.default;
//             return <Alerts {...props} />
//         }
//     }
// );
var CollectionStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/collectionStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var BrandStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/brandStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var VoucherStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/voucherStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MembershipStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/membershipStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var FanStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/fanStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var ColleagueStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/colleagueStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyOrders = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myOrders/index'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Vouchers = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/vouchers'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Memberships = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/memberships'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Fans = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/fans'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Brands = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/brands'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Affiliater = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/affiliater'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var AffiliateCommission = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/affiliate-commission'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Overview = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/Overview/index'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Campaigns = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/campaigns'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Collections = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/collections'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Roles = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/roles'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Categories = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/categories'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var UserReports = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/userReports'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Banner = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/banner'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var Commission = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/commission'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var ExchangeRate = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/exchangerate'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var OrderHistory = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/orderhistory'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var OrderDetailHistory = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/orderDetail'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var UserReportStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/userReportStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var OrderHistoryStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/orderHistoryStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var SaleReportStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/saleReportStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var SaleReportAdmin = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/saleReport'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var IncomeStatement = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/incomeStatementStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var IncomeStatementAdmin = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/incomeStatement'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var PayoutTransactionAdmin = Loadable({
    loader: function () { return import('../../../pages/master-admin/components/payoutTransaction'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var PayoutTransactionStaff = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/payoutTransactionStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var QrScanner = Loadable({
    loader: function () { return import('./BrandScannerQR'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var AffiliateRegistration = Loadable({
    loader: function () { return import('../../../pages/affiliate/index'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var QRCheckHistory = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/qrCheckHistory'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var TrackingConversion = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/trackingConversionStaff'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var PhysicalOrders = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myAdmin/components/physicalOrders'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyUsageHistory = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myUsageHistory'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
var MyTrackingConversion = Loadable({
    loader: function () { return import('../../../pages/brand-admin/myTrackingConversion'); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    }
});
export var BRAND_ADMIN_TAB_NAME = {
    brandAdmin: ROLE_TYPE.brandRole.toString(),
    manage: "/manage/",
    admin: "/admin/",
    affiliate: "/affiliate/",
    link: "/link/"
};
export var MANAGE_TAB_NAME = {
    manage_overview: "overview",
    manage_user_profile: "profile",
    manage_favoritebrand: "favoritebrand",
    manage_category: "category",
    manage_role: "role",
    manage_campaign: "campaign",
    manage_collection: "collection",
    manage_whitelist: "whitelist",
    manage_brand: "brand",
    manage_voucher: "voucher",
    manage_membership: "membership",
    manage_fan: "fan",
    manage_order: "order",
    manage_colleague: "colleague",
    manage_user_report: "userreport",
    manage_promotional: "promotional",
    manage_setting: "setting",
    manage_report: "report",
    manage_commission: "commission",
    manage_exchange_rate: "exchangerate",
    manage_edit: "edit",
    manage_order_history: "orderhistory",
    manage_order_detail_history: "orderdetailhistory",
    manage_price_history: "pricehistory",
    manage_sale_report: "salereport",
    manage_income_statement: "incomestatement",
    manage_payout_transaction: "payouttransaction",
    manage_qrscanner: "qrscanner",
    manage_banner: "banner",
    purchased_item: "purchaseditem",
    free_item: "freeitem",
    manage_affiliate: "affiliates",
    register: "register",
    manage_link: "link",
    manage_usage_history: "usage-history",
    create_link: "createlink",
    manage_qr_check_history: "qr-check-history",
    manage_physical_order: "physical-order",
    manage_conversion: "tracking-conversion",
    manage_affiliate_commission: "affiliate-commission"
};
export var BRAND_ADMIN_MENU_TABS = [
    {
        id: 0,
        name: MANAGE_TAB_NAME.manage_user_profile,
        url: SUB_URL + "/my/profile",
        label: "User Profile",
        component: React.createElement(MyUserProfile, null),
        translateText: "menus.user_profile"
    },
    {
        id: 1,
        name: MANAGE_TAB_NAME.manage_order,
        url: SUB_URL + "/my/order",
        label: "Orders",
        component: React.createElement(MyOrders, null),
        translateText: "menus.my_orders"
    },
    {
        id: 2,
        name: MANAGE_TAB_NAME.purchased_item,
        url: SUB_URL + "/my/purchaseditem",
        label: "Purchased Items",
        component: React.createElement(MyPurchasedItems, null),
        translateText: "menus.purchased_item"
    },
    {
        id: 3,
        name: MANAGE_TAB_NAME.free_item,
        url: SUB_URL + "/my/freeitem",
        label: "Free Items",
        component: React.createElement(MyFreeItems, null),
        translateText: "menus.free_item"
    },
    // {
    //     id: 2,
    //     name: MANAGE_TAB_NAME.manage_fan,
    //     url: SUB_URL + "/my/fan",
    //     label: "E-Fans",
    //     component: <MyFans />,
    // },
    // {
    //     id: 3,
    //     name: MANAGE_TAB_NAME.manage_voucher,
    //     url: SUB_URL + "/my/voucher",
    //     label: "E-Vouchers",
    //     component: <MyVouchers />,
    // },
    // {
    //     id: 4,
    //     name: MANAGE_TAB_NAME.manage_membership,
    //     url: SUB_URL + "/my/membership",
    //     label: "E-Memberships",
    //     component: <MyMemberships />,
    // },
    {
        id: 5,
        name: MANAGE_TAB_NAME.manage_favoritebrand,
        url: SUB_URL + "/my/favoritebrand",
        label: "Favorite Brands",
        component: React.createElement(MyFavoriteBrands, null),
        translateText: "menus.my_favorite_brands"
    },
    {
        id: 6,
        name: MANAGE_TAB_NAME.manage_link,
        url: SUB_URL + "/my/link",
        label: "Link",
        component: React.createElement(MyLink, null),
        translateText: "menus.affiliate_link"
    },
    {
        id: 8,
        name: MANAGE_TAB_NAME.manage_usage_history,
        url: SUB_URL + "/my/usage-history",
        label: "Usage History",
        component: React.createElement(MyUsageHistory, null),
        translateText: "menus.usage_history"
    },
    {
        id: 9,
        name: MANAGE_TAB_NAME.manage_conversion,
        url: SUB_URL + "/my/tracking-conversion",
        label: "Conversion",
        component: React.createElement(MyTrackingConversion, null),
        translateText: "menus.conversion"
    },
    // {
    //     id: 6,
    //     name: MANAGE_TAB_NAME.manage_affiliate,
    //     url: SUB_URL + "/my/affiliate",
    //     label: "Affiliate",
    //     component: <AffiliateRegister/>,
    //     translateText: "menus.affiliate_register"
    // },
    // {
    //     id: 8,
    //     name: MANAGE_TAB_NAME.create_link,
    //     url: SUB_URL + "/my/create",
    //     label: "Create Link",
    //     component: <MyCreateLink />,
    //     translateText: "menus.create_link"
    // },
    {
        id: 10,
        name: BRAND_ADMIN_TAB_NAME.brandAdmin,
        url: SUB_URL + "",
        label: "Brand Admin",
        component: React.createElement(MyAdmnin, null),
        translateText: "menus.brand_admin"
    },
    // {
    //     id: 5,
    //     name: MANAGE_TAB_NAME.manage_favoritebrand,
    //     url: SUB_URL + "/my/favoritebrand/register",
    //     label: "Affiliate Register",
    //     component: <RegisterForm/>,
    //     translateText: "menus.affiliate_register"
    // },
];
export var STAFF_MENU_TABS = [
    {
        id: 0,
        name: MANAGE_TAB_NAME.manage_overview,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_overview,
        label: "Overview",
        component: React.createElement(Overview, null),
        translateText: "menus.overview"
    },
    {
        id: 1,
        name: MANAGE_TAB_NAME.manage_brand,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_brand,
        label: "Brands",
        component: React.createElement(Brands, null),
        translateText: "menus.brands"
    },
    {
        id: 2,
        name: MANAGE_TAB_NAME.manage_campaign,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_campaign,
        label: "Campaigns",
        component: React.createElement(Campaigns, null),
        translateText: "menus.campaigns"
    },
    {
        id: 3,
        name: MANAGE_TAB_NAME.manage_collection,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_collection,
        label: "Collections",
        component: React.createElement(Collections, null),
        translateText: "menus.collection"
    },
    {
        id: 4,
        name: MANAGE_TAB_NAME.manage_fan,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_fan,
        label: "e-Fans",
        component: React.createElement(Fans, null),
    },
    {
        id: 5,
        name: MANAGE_TAB_NAME.manage_voucher,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_voucher,
        label: "e-Vouchers",
        component: React.createElement(Vouchers, null),
    },
    {
        id: 6,
        name: MANAGE_TAB_NAME.manage_membership,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_membership,
        label: "e-Memberships",
        component: React.createElement(Memberships, null),
    },
    {
        id: 7,
        name: MANAGE_TAB_NAME.manage_commission,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_commission,
        label: "Commission",
        component: React.createElement(Commission, null),
        translateText: "menus.commission"
    },
    {
        id: 8,
        name: MANAGE_TAB_NAME.manage_exchange_rate,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_exchange_rate,
        label: "Exchange Rate",
        component: React.createElement(ExchangeRate, null),
        translateText: "menus.exchange_rate"
    },
    {
        id: 9,
        name: MANAGE_TAB_NAME.manage_order_history,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_order_history,
        label: "Orders",
        component: React.createElement(OrderHistory, null),
        translateText: "menus.my_orders"
    },
    {
        id: 10,
        name: MANAGE_TAB_NAME.manage_order_detail_history,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_order_detail_history,
        label: "Orders Detail",
        component: React.createElement(OrderDetailHistory, null),
        translateText: "menus.orders_detail"
    },
    {
        id: 11,
        name: MANAGE_TAB_NAME.manage_income_statement,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_income_statement,
        label: "Income Statement",
        component: React.createElement(IncomeStatementAdmin, null),
        translateText: "menus.income_statement"
    },
    {
        id: 12,
        name: MANAGE_TAB_NAME.manage_payout_transaction,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_payout_transaction,
        label: "Payout Transaction",
        component: React.createElement(PayoutTransactionAdmin, null),
        translateText: "menus.payout_transaction"
    },
    {
        id: 13,
        name: MANAGE_TAB_NAME.manage_sale_report,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_sale_report,
        label: "Sale Reports",
        component: React.createElement(SaleReportAdmin, null),
        translateText: "menus.sale_reports"
    },
    {
        id: 14,
        name: MANAGE_TAB_NAME.manage_role,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_role,
        label: "Roles",
        component: React.createElement(Roles, null),
        translateText: "menus.roles"
    },
    {
        id: 15,
        name: MANAGE_TAB_NAME.manage_category,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_category,
        label: "Categories",
        component: React.createElement(Categories, null),
        translateText: "menus.Category"
    },
    {
        id: 16,
        name: MANAGE_TAB_NAME.manage_user_report,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_user_report,
        label: "User Reports",
        component: React.createElement(UserReports, null),
        translateText: "menus.user_reports"
    },
    {
        id: 17,
        name: MANAGE_TAB_NAME.manage_banner,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_banner,
        label: "Banner",
        component: React.createElement(Banner, null),
        translateText: "menus.banner"
    },
    {
        id: 18,
        name: MANAGE_TAB_NAME.manage_affiliate,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_affiliate,
        label: "Affiliater",
        component: React.createElement(Affiliater, null),
        translateText: "menus.affiliater"
    },
    {
        id: 19,
        name: MANAGE_TAB_NAME.manage_physical_order,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_physical_order,
        label: "Physical Orders",
        component: React.createElement(PhysicalOrders, { role: "admin" }),
        translateText: "menus.physical_orders"
    },
    {
        id: 20,
        name: MANAGE_TAB_NAME.manage_conversion,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_conversion,
        label: "Tracing Conversion",
        component: React.createElement(TrackingConversion, { role: "admin" }),
        translateText: "menus.conversion"
    },
    // {
    //     id: 21,
    //     name: MANAGE_TAB_NAME.manage_affiliate_commission,
    //     url: SUB_URL + BRAND_ADMIN_TAB_NAME.admin + MANAGE_TAB_NAME.manage_affiliate_commission,
    //     label: "Commission Settings",
    //     component: <AffiliateCommission />,
    //     translateText: "menus.affiliate_commission"
    // },
];
export var GLOBAL_TAB = [
    // {
    //     id: 5,
    //     name: "getmyqrcode",
    //     label: "Get My QR Code",
    //     component: null,
    //     translateText: "menus.get_my_QR_code"
    // },
    // {
    //     id: 6,
    //     name: "scanqr",
    //     label: "Scan QR",
    //     component: null
    // },
    {
        id: 7,
        name: "logout",
        label: "Logout",
        url: SUB_URL + "/login",
        component: null,
        translateText: "menus.logout"
    },
];
export var GLOBAL_TAB_AFFILIATE = [
    {
        id: 2,
        name: MANAGE_TAB_NAME.register + "-" + MANAGE_TAB_NAME.manage_affiliate,
        url: SUB_URL + '/register-affiliate',
        label: "Affiliate Registration",
        component: React.createElement(AffiliateRegistration, null),
        translateText: "affiliate_page.affiliate_registration"
    },
];
export var CAMPAIGN_SETTING_TABS = [
    {
        id: 0,
        name: MANAGE_TAB_NAME.manage_fan,
        label: "e-Fan Requests",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign + "/:id/" + MANAGE_TAB_NAME.manage_fan,
        translateText: "menus.fan_requests"
    },
    {
        id: 1,
        name: MANAGE_TAB_NAME.manage_voucher,
        label: "e-Voucher Requests",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign + "/:id/" + MANAGE_TAB_NAME.manage_voucher,
        translateText: "menus.voucher_requests"
    },
    {
        id: 2,
        name: MANAGE_TAB_NAME.manage_membership,
        label: "e-Membership Requests",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign + "/:id/" + MANAGE_TAB_NAME.manage_membership,
        translateText: "menus.membership_requests"
    },
    {
        id: 3,
        name: MANAGE_TAB_NAME.manage_promotional,
        label: "Promotional Items",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign + "/:id/" + MANAGE_TAB_NAME.manage_promotional,
        translateText: "menus.promotional_items"
    },
    {
        id: 4,
        name: MANAGE_TAB_NAME.manage_setting,
        label: "Setting",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign + "/:id/" + MANAGE_TAB_NAME.manage_setting,
        translateText: "menus.setting"
    },
    {
        id: 5,
        name: MANAGE_TAB_NAME.manage_report,
        label: "Report",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign + "/:id/" + MANAGE_TAB_NAME.manage_report,
        translateText: "menus.report"
    }
];
export var BRAND_SETTING_TABS = [
    {
        id: 1,
        name: MANAGE_TAB_NAME.manage_setting,
        label: "Setting",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_brand + "/:id",
        translateText: "menus.setting"
    },
    {
        id: 2,
        name: MANAGE_TAB_NAME.manage_edit,
        label: "Edit",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_brand + "/:id/" + MANAGE_TAB_NAME.manage_edit,
        translateText: "menus.edit"
    }
];
export var COLLECTION_SETTING_TABS = [
    {
        id: 1,
        name: MANAGE_TAB_NAME.manage_whitelist,
        label: "Whitelist",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_collection + "/:id/" + MANAGE_TAB_NAME.manage_whitelist,
        translateText: "menus.whitelist"
    },
    {
        id: 2,
        name: MANAGE_TAB_NAME.manage_edit,
        label: "Edit",
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_collection + "/:id/" + MANAGE_TAB_NAME.manage_edit,
        translateText: "menus.edit"
    }
];
export var LINK_TABS = [
// {
//     id: 1,
//     name: MANAGE_TAB_NAME.create_link,
//     url: SUB_URL + "/my/create-link",
//     label: "Create Link",
//     component: <CreateLink />,
//     translateText: "menus.create_link"
// },
// {
//     id: 2,
//     name: MANAGE_TAB_NAME.manage_link,
//     url: SUB_URL + "/my/link",
//     label: "Link",
//     component: <ManageLink />,
//     translateText: "menus.manage_link"
// },
];
export var MANAGE_TABS = [
    //    {
    //         id: 1,
    //         name: MANAGE_TAB_NAME.manage_brand,
    //         url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_brand,
    //         label: "Brands",
    //         component: <BrandStaff />,
    //         translateText: "menus.brands"
    //     },
    {
        id: 1,
        name: MANAGE_TAB_NAME.manage_brand,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_brand,
        label: "Brands",
        component: React.createElement(BrandStaff, null),
        translateText: "menus.brands"
    },
    {
        id: 2,
        name: MANAGE_TAB_NAME.manage_campaign,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_campaign,
        label: "Campaigns",
        component: React.createElement(CampaignStaff, null),
        translateText: "menus.campaigns"
    },
    {
        id: 3,
        name: MANAGE_TAB_NAME.manage_collection,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_collection,
        label: "Collections",
        component: React.createElement(CollectionStaff, null),
        translateText: "menus.collection"
    },
    {
        id: 4,
        name: MANAGE_TAB_NAME.manage_fan,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_fan,
        label: "e-Fans",
        component: React.createElement(FanStaff, null),
    },
    {
        id: 5,
        name: MANAGE_TAB_NAME.manage_voucher,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_voucher,
        label: "e-Vouchers",
        component: React.createElement(VoucherStaff, null),
    },
    {
        id: 6,
        name: MANAGE_TAB_NAME.manage_membership,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_membership,
        label: "e-Memberships",
        component: React.createElement(MembershipStaff, null),
    },
    {
        id: 14,
        name: MANAGE_TAB_NAME.manage_physical_order,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_physical_order,
        label: "Physical Orders",
        component: React.createElement(PhysicalOrders, { role: "brandAdmin" }),
        translateText: "menus.physical_orders"
    },
    {
        id: 15,
        name: MANAGE_TAB_NAME.manage_qr_check_history,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_qr_check_history,
        label: "QR Check History",
        component: React.createElement(QRCheckHistory, null),
        translateText: "menus.qr_check_history"
    },
    {
        id: 16,
        name: MANAGE_TAB_NAME.manage_conversion,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_conversion,
        label: "Tracing Conversion",
        component: React.createElement(TrackingConversion, { role: "brandAdmin" }),
        translateText: "menus.conversion"
    },
    {
        id: 7,
        name: MANAGE_TAB_NAME.manage_colleague,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_colleague,
        label: "Colleagues",
        component: React.createElement(ColleagueStaff, null),
        translateText: "menus.colleagues"
    },
    {
        id: 8,
        name: MANAGE_TAB_NAME.manage_order_history,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_order_history,
        label: "Orders",
        component: React.createElement(OrderHistoryStaff, null),
        translateText: "menus.orders"
    },
    {
        id: 9,
        name: MANAGE_TAB_NAME.manage_income_statement,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_income_statement,
        label: "Income Statement",
        component: React.createElement(IncomeStatement, null),
        translateText: "menus.income_statement"
    },
    {
        id: 10,
        name: MANAGE_TAB_NAME.manage_payout_transaction,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_payout_transaction,
        label: "Payout Transaction",
        component: React.createElement(PayoutTransactionStaff, null),
        translateText: "menus.payout_transaction"
    },
    {
        id: 11,
        name: MANAGE_TAB_NAME.manage_sale_report,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_sale_report,
        label: "Sale Reports",
        component: React.createElement(SaleReportStaff, null),
        translateText: "menus.sale_reports"
    },
    {
        id: 12,
        name: MANAGE_TAB_NAME.manage_user_report,
        url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_user_report,
        label: "User Reports",
        component: React.createElement(UserReportStaff, null),
        translateText: "menus.user_reports"
    },
    {
        id: 13,
        name: MANAGE_TAB_NAME.manage_qrscanner,
        // url: SUB_URL + BRAND_ADMIN_TAB_NAME.manage + MANAGE_TAB_NAME.manage_qrscanner,
        url: "",
        label: "QR Scanner",
        // component: <QrScanner />,
        component: null,
        translateText: "menus.qr_scanner"
    },
];
export var GENERAL_TAB_NAME = {
    overview: "overview",
    brand: "brand",
    voucher: "voucher",
    membership: "membership",
    collection: "collection",
    // aboutLoyaty: "About Loyalty",
    fan: "fan",
    free: "free",
    category: "category",
    language: "language",
    obrandingCard: 'OBranding Card',
    partner: 'Partner',
    digitalProduct: 'Digital Product'
};
export var GENERAL_TABS = [
    // {
    //     id: 0,
    //     name: GENERAL_TAB_NAME.overview,
    //     label: "Overview",
    //     url: SUB_URL + "/overview",
    //     hasSubTab: false,
    //     translateText: "menus.overview"
    // },
    // {
    //     id: 1,
    //     name: GENERAL_TAB_NAME.brand,
    //     label: "Brands",
    //     url: SUB_URL + "/brand",
    //     hasSubTab: false,
    //     translateText: "menus.brands"
    // },
    // {
    //     id: 2,
    //     name: GENERAL_TAB_NAME.fan,
    //     label: "E-Fans",
    //     url: SUB_URL + "/fan",
    //     hasSubTab: false
    // },
    // {
    //     id: 3,
    //     name: GENERAL_TAB_NAME.voucher,
    //     label: "E-Vouchers",
    //     url: SUB_URL + "/voucher",
    //     hasSubTab: false
    // },
    // {
    //     id: 4,
    //     name: GENERAL_TAB_NAME.membership,
    //     label: "E-Memberships",
    //     url: SUB_URL + "/membership",
    //     hasSubTab: false
    // },
    // {
    //     id: 5,
    //     name: GENERAL_TAB_NAME.collection,
    //     label: "Collections",
    //     url: SUB_URL + "/collection",
    //     hasSubTab: false,
    //     translateText: "menus.collection"
    // },
    // {
    //     id: 6,
    //     name: GENERAL_TAB_NAME.free,
    //     label: "Free",
    //     url: SUB_URL + "/search-result?pricingType=0&type=voucher",
    //     hasSubTab: false,
    //     translateText: "common.free"
    // },
    {
        id: 9,
        name: GENERAL_TAB_NAME.obrandingCard,
        label: "OBranding Card",
        url: SUB_URL + "/sanh-card",
        translateText: "menus.obranding_card",
        hasSubTab: false
    },
    {
        id: 10,
        name: GENERAL_TAB_NAME.partner,
        label: "Partners",
        url: SUB_URL + "/partners",
        translateText: "menus.brand_partner",
        hasSubTab: false
    },
    {
        id: 11,
        name: GENERAL_TAB_NAME.digitalProduct,
        label: "Digital Product",
        url: SUB_URL + "/digital-product",
        translateText: "menus.digital_product",
        hasSubTab: false
    },
    {
        id: 7,
        name: GENERAL_TAB_NAME.category,
        label: "Categories",
        url: "",
        hasSubTab: true,
        translateText: "menus.Category"
    },
    {
        id: 8,
        name: GENERAL_TAB_NAME.language,
        label: "Languages",
        url: "",
        hasSubTab: true,
        translateText: "menus.languages"
    },
    // {
    //     id: 9,
    //     name: GENERAL_TAB_NAME.aboutLoyaty,
    //     label: "About Loyalty",
    //     url: SUB_URL + "/about-loyalty",
    //     hasSubTab: false
    // }
];
var SidebarNav = function (props) {
    var _a = useContext(MyContext), setOpenModalQRCode = _a.setOpenModalQRCode, setOpenModalScannerQRCode = _a.setOpenModalScannerQRCode;
    var navigate = useNavigate();
    var accessToken = getAccessToken();
    var t = useTranslation().t;
    var onCloseSideBar = props.onCloseSideBar, type = props.type, onLogout = props.onLogout, isRouteAdmin = props.isRouteAdmin, handleDropdownTabs = props.handleDropdownTabs, categories = props.categories, languages = props.languages;
    var _b = useState({
        brandAdmin: false,
        category: false,
        language: false,
    }), collapse = _b[0], setCollapse = _b[1];
    var _c = useState(null), userData = _c[0], setUserData = _c[1];
    var language = getItem(LANGUAGE_CODE);
    var _d = useState(language ? language : "vn"), languageType = _d[0], setLanguageType = _d[1];
    useEffect(function () {
        var userData = userStorage.get();
        if (userData) {
            setUserData(userData);
        }
        NetworkConnector.setNavigate(navigate);
    }, []);
    var handleChangeAdminMenuTab = function (event, tab) {
        event.preventDefault();
        if (!tab)
            return;
        if (tab.name == BRAND_ADMIN_TAB_NAME.brandAdmin) {
            setCollapse(__assign(__assign({}, collapse), { brandAdmin: !collapse.brandAdmin }));
        }
        else {
            onCloseSideBar();
            if (tab.name == "logout") {
                onLogout("LOGOUT");
            }
            else if (tab.name == "getmyqrcode") {
                setOpenModalQRCode(true);
            }
            else if (tab.name == "scanqr") {
                setOpenModalScannerQRCode(true);
            }
            else {
                if (tab.url) {
                    redirectToAction(tab.url);
                }
            }
        }
    };
    var handleChangeAdminTab = function (tab) {
        if (tab.url) {
            onCloseSideBar();
            redirectToAction(tab.url);
        }
        else {
            if (tab.name == MANAGE_TAB_NAME.manage_qrscanner) {
                onCloseSideBar();
                setOpenModalScannerQRCode(true);
            }
        }
    };
    var handleSignin = function () {
        onCloseSideBar();
        onNavigateMenu("/login");
    };
    var handleSignUp = function () {
        onCloseSideBar();
        onNavigateMenu("/signup");
    };
    var redirectToAction = function (url, data) {
        // window.location.replace(url);
        var location = {
            pathname: url,
        };
        var options = {
            id: data,
            isCheck: true
        };
        if (!data) {
            window.location.replace(url);
        }
        else {
            navigate(location, { state: options });
        }
    };
    var navigateToAction = function (url, categoryId, type, pricingType) {
        var params = [];
        if (categoryId != null) {
            params.push("categoryId=".concat(categoryId));
        }
        if (pricingType != null) {
            params.push("pricingType=".concat(pricingType));
        }
        if (type) {
            params.push("type=".concat(type));
        }
        url = "/" + url + "?" + params.join("&");
        navigate(url);
        window.location.reload();
    };
    var onNavigateMenu = function (url) {
        var location = {
            pathname: url,
        };
        navigate(location);
    };
    var renderAvatar = function () {
        if (userData && userData.avatar) {
            return (React.createElement(Link, { to: BRAND_ADMIN_MENU_TABS[0].url },
                React.createElement("img", { src: userData.avatar, className: "cursor-pointer rounded-full w-16 h-16 object-cover", alt: "obranding-avatar-user", loading: "lazy" })));
        }
        return (React.createElement(Link, { to: BRAND_ADMIN_MENU_TABS[0].url, className: 'text-[#607d8b]' },
            React.createElement(UserCircleIcon, { className: 'cursor-pointer w-20 h-20 mr-2' })));
    };
    var renderRole = function () {
        var role = "";
        if (checkRoleUser(DEFAULT_ROLE.brandExecutive.name)) {
            role = t("common.brand_executive");
        }
        if (checkRoleUser(DEFAULT_ROLE.brandAdmin.name)) {
            role = t("common.brand_admin");
        }
        if (checkRoleUser(DEFAULT_ROLE.superAdmin.name) && isRouteAdmin) {
            role = t("common.supper_admin");
        }
        return role;
    };
    var handleClickLanguage = function (type) {
        setLanguageType(type);
        storeItem(LANGUAGE_CODE, type);
        window.location.reload(true);
    };
    return (React.createElement("div", { className: "sidebar-container w-60 shadow-md bg-white absolute", id: "sidenavSecExample" },
        React.createElement("div", { className: "px-6 py-6 bg-brand-new max-sm:px-6 max-sm:py-2" },
            React.createElement("div", { className: 'sidebar-header flex flex-row justify-between items-center' },
                React.createElement(Link, { to: "/", className: 'flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 lg:mt-0 no-underline' },
                    React.createElement("img", { src: Logo, className: 'max-w-[100px] h-auto max-sm:w-[46px] max-sm:h-[unset]', alt: "obranding logo", loading: "lazy" })),
                React.createElement("img", { className: 'w-8 h-8 cursor-pointer max-sm:w-6 max-sm:h-6', src: CloseIcon, alt: "obranding-close-icon", onClick: onCloseSideBar }))),
        accessToken ? React.createElement("div", { className: "w-full px-6 flex items-center my-4" },
            renderAvatar(),
            React.createElement("div", { className: "description-avatar ml-5 flex flex-col items-start" },
                React.createElement("h3", { className: 'm-0 mb-2' }, userData && userData.displayName ? userData.displayName : t("common.not_display_name")),
                React.createElement("p", { className: "title m-0" }, renderRole()))) : null,
        React.createElement("div", { className: "memberships-filter-items grid grid-cols-1 gap-3 px-8" },
            type === "GENERAL" ? GENERAL_TABS.map(function (tab) { return (React.createElement(React.Fragment, null, !tab.hasSubTab ? (React.createElement("div", { className: "memberships-filter-item-content cursor-pointer flex flex-row justify-between items-center", key: tab.id, onClick: function (event) { return handleChangeAdminMenuTab(event, tab); } },
                React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: 'no-underline title-xl w-full pb-4' }, tab.translateText ? t(tab.translateText) : tab.label))) : (React.createElement(React.Fragment, null, tab.name == "language" ? (React.createElement("div", { className: "memberships-filter-item-content" },
                React.createElement("p", { className: "title-xl my-0 flex flex-row justify-between items-center cursor-pointer mb-4", onClick: function () { return setCollapse(__assign(__assign({}, collapse), { language: collapse.language ? false : true })); } },
                    React.createElement("p", { className: "my-0" }, t("menus.languages")),
                    React.createElement("div", { className: "memberships-filter-item-icon" }, collapse && collapse.language
                        ? React.createElement(ChevronDownIcon, { className: 'icon-outline h-5 w-5 pr-2 cursor-pointer' })
                        : React.createElement(ChevronUpIcon, { className: 'icon-outline h-5 w-5 pr-2 cursor-pointer' }))),
                collapse && collapse.language ?
                    React.createElement(React.Fragment, null, languages && languages.map(function (item) { return (React.createElement("div", { key: item.value, className: 'admin-sub-tabs font-semibold text-base ml-5 report-wrapper flex justify-between py-3 cursor-pointer', onClick: function () { return handleClickLanguage(item.value); } },
                        React.createElement("div", { className: 'text-base font-semibold self-center text-left', id: item.value }, item.label),
                        item.value == language ? (React.createElement(CheckIcon, { className: 'text-brand-new pr-2 w-6 h-6 stroke-2' })) : null)); }))
                    : null)) : null)))); }) : null,
            accessToken && type === "PROFILE" ? (handleDropdownTabs() || []).map(function (tab) { return (React.createElement(React.Fragment, null,
                tab.name !== BRAND_ADMIN_TAB_NAME.brandAdmin && tab.name != "logout" && (React.createElement("div", { className: "memberships-filter-item-content cursor-pointer flex justify-between items-center", key: tab.id, onClick: function (event) { return handleChangeAdminMenuTab(event, tab); } },
                    React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: 'no-underline title-xl pb-4 w-full' }, tab.translateText ? t(tab.translateText) : tab.label))),
                tab.name !== BRAND_ADMIN_TAB_NAME.brandAdmin && tab.name == "logout" && (React.createElement("div", { className: "memberships-filter-item-content cursor-pointer flex justify-between items-center", key: tab.id, onClick: function (event) { return handleChangeAdminMenuTab(event, tab); } },
                    React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: 'no-underline title-xl pb-4 w-full' }, tab.translateText ? t(tab.translateText) : tab.label))),
                tab.name === BRAND_ADMIN_TAB_NAME.brandAdmin && (React.createElement("div", { className: "memberships-filter-item-content" },
                    React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: 'no-underline title-xl flex flex-row justify-between items-center mb-4', onClick: function (event) { return handleChangeAdminMenuTab(event, tab); } },
                        React.createElement("p", { className: "my-0" }, tab.translateText ? t(tab.translateText) : tab.label),
                        React.createElement("div", { className: "memberships-filter-item-icon" }, collapse.brandAdmin
                            ? React.createElement(ChevronDownIcon, { className: 'icon-outline h-5 w-5 pr-2 cursor-pointer' })
                            : React.createElement(ChevronUpIcon, { className: 'icon-outline h-5 w-5 pr-2 cursor-pointer' }))),
                    collapse.brandAdmin &&
                        filterTabsByPermission(MANAGE_TABS).map(function (tab) { return (React.createElement("div", { className: "admin-sub-tabs ml-5 cursor-pointer py-3", key: tab.id, onClick: function () { return handleChangeAdminTab(tab); } },
                            React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: 'no-underline font-semibold text-base text-[#607d8b] block' }, tab.translateText ? t(tab.translateText) : tab.label))); }))))); }) : type == "PROFILE" ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "memberships-filter-item-content cursor-pointer mt-4", onClick: handleSignin },
                        React.createElement(Link, { to: "/login", className: 'no-underline block title-l pb-4' }, t("common.sign_in"))),
                    React.createElement("div", { className: "memberships-filter-item-content cursor-pointer", onClick: handleSignUp },
                        React.createElement(Link, { to: "/login", className: 'no-underline block title-l pb-4' }, t("common.sign_up"))))
                : null)));
};
export default SidebarNav;
